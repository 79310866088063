        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="12"
                                        >
              <div
                                class="d-flex justify-content-end mb-2"
                              >
                                  <div
                                        >
                                            <open-sidebar-button
  label="Добавить тип поступления"
    :sidebar.sync="isSidebarActive"
      variant="primary"
        />
                  </div>
                              </div>
            </b-col>
                                        <b-col
                                                                                                >
                            <create-update-sidebar
  title="Добавить тип поступления"
  api="http://api.bc-sport.ru/api/receipttypes"
    :refresh.sync="refresh"
  :fields="[{&quot;name&quot;:&quot;name&quot;,&quot;label&quot;:&quot;\u0422\u0438\u043f&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;text&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;}]"
  :is-sidebar-active.sync="isSidebarActive"
/>            </b-col>
                                        <b-col
                                                                                                >
                            <update-sidebar
  title="Редактировать тип поступления"
  api="http://api.bc-sport.ru/api/receipttypes"
  :apiId="editSidebarId"
  getApi="http://api.bc-sport.ru/api/receipttypes"
    :refresh.sync="refresh"
  :fields="[{&quot;name&quot;:&quot;name&quot;,&quot;label&quot;:&quot;\u0422\u0438\u043f&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;text&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;}]"
  :is-sidebar-active.sync="editSidebarRef"
/>            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <basic-table
  :columns="[{&quot;key&quot;:&quot;name&quot;,&quot;label&quot;:&quot;\u0422\u0438\u043f&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;actions&quot;,&quot;label&quot;:&quot;&quot;,&quot;tdClass&quot;:&quot;text-right&quot;,&quot;actions&quot;:[{&quot;label&quot;:&quot;\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c&quot;,&quot;name&quot;:&quot;Edit&quot;,&quot;icon&quot;:&quot;EditIcon&quot;,&quot;alert&quot;:false},{&quot;label&quot;:&quot;\u0423\u0434\u0430\u043b\u0438\u0442\u044c&quot;,&quot;icon&quot;:&quot;TrashIcon&quot;,&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/receipttypes&quot;,&quot;method&quot;:&quot;delete&quot;}]}]"
          :refresh="refresh"
          :editSidebarRef.sync="editSidebarRef"
                                                :editSidebarId.sync="editSidebarId"
    api="http://api.bc-sport.ru/api/receipttypes"
          />
            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                                    import OpenSidebarButton from '@core/components/button/OpenSidebarButton.vue'
                                                                          import CreateUpdateSidebar from '@core/components/sidebar/CreateUpdateSidebar.vue'
                                                              import UpdateSidebar from '@core/components/sidebar/UpdateSidebar.vue'
                                                              import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                                          OpenSidebarButton,
                                                                                            CreateUpdateSidebar,
                                                                              UpdateSidebar,
                                                                              BasicTable,
                                          },
  setup() {
                const isSidebarActive = ref(false)
            const editSidebarRef = ref(false)
            const editSidebarId = ref(null)
            const refresh = ref(false)
          
          return {
            isSidebarActive,
            editSidebarRef,
            editSidebarId,
            refresh,
            }
      },
}
</script>
    